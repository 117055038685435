/**
 * Overwrites obj1's values with obj2's and adds obj2's if non existent in obj1
 * @param obj1
 * @param obj2
 * @returns obj3 a new object based on obj1 and obj2
 */
function mergeOptions(obj1, obj2) {
    var obj3 = {},
        attrname;
    for (attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
}

function hasClassName(el, className) {
    if (el.classList) {
        return el.classList.contains(className);
    } else {
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
    }
}

function addClassName(el, className) {
    if (el.classList) {
        el.classList.add(className);
    } else {
        el.className += ' ' + className;
    }
}

function removeClassName(el, className) {
    if (el.classList) {
        el.classList.remove(className);
    } else {
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
}

function hasParent(el, id) {
    if (el) {
        do {
            if (el.id === id) {
                return true;
            }
            if (el.nodeType === 9) {
                break;
            }
        }
        while((el = el.parentNode));
    }
    return false;
}

function findAncestor(el, cls) {
    while ((el = el.parentElement) && !hasClassName(el, cls));
    return el;
}

function urlEncodeParams(params) {
    var urlEncodedData = '',
        urlEncodedDataPairs = [];

    // inspired from the Mozilla developer documentation
    // source: https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Forms/Sending_forms_through_JavaScript
    for (var name in params) {
        urlEncodedDataPairs.push(encodeURIComponent(name) + '=' + encodeURIComponent(params[name]));
    }
    urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

    return urlEncodedData;
}

function prepareRequest(url, method) {
    var request = new XMLHttpRequest();
    request.open(method, url, true);
    if (method.toLowerCase() === 'post') {
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }

    // ask for json output
    request.setRequestHeader('Accept', 'application/json');

    return request;
}

function sendRequest(preparedRequest, data) {
    preparedRequest.send(data);
}

/**
 * Check for the existence of a nested property
 *
 * var test = {level1:{level2:{level3:'level3'}} };
 *
 * checkNested(test, 'level1', 'level2', 'level3'); // true
 * checkNested(test, 'level1', 'level2', 'foo'); // false
 */
function checkNested(obj /*, level1, level2, ... levelN*/) {
    var args = Array.prototype.slice.call(arguments, 1);

    for (var i = 0; i < args.length; i++) {
        if (!obj || !obj.hasOwnProperty(args[i])) {
            return false;
        }
        obj = obj[args[i]];
    }
    return true;
}

function isPassiveEventsSupported(win) {
    let supportsPassiveEvents;

    if (typeof win !== 'undefined' && typeof win.addEventListener === 'function') {
        const options = {
            get passive() {
                return (supportsPassiveEvents = true);
            },
        };
        // have to set and remove a no-op listener instead of null
        // (which was used previously), because Edge v15 throws an error
        // when providing a null callback.
        // https://github.com/rafgraph/detect-passive-events/pull/3
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const noop = () => {};
        win.addEventListener('p', noop, options);
        win.removeEventListener('p', noop, false);
    } else {
        supportsPassiveEvents = false;
    }

    return supportsPassiveEvents;
}

const supportsPassiveEvents = isPassiveEventsSupported(window);

export { 
    mergeOptions, 
    hasClassName,
    addClassName,
    removeClassName,
    hasParent,
    findAncestor,
    urlEncodeParams,
    prepareRequest,
    sendRequest,
    checkNested,
    isPassiveEventsSupported,
    supportsPassiveEvents
};
